@use 'src/src/app/scss/abstracts' as *;

.video-and-image-wrapper {
  // @include skeleton;

  display: block;
  overflow: hidden;
  border-radius: $border-radius-s;

  @include mq($until: sm) {
    border-radius: $border-radius-2xs;
  }
}

.video-and-image {
  $block-name: &;

  &__inner {
    position: relative;
    z-index: 0;
    overflow: hidden;
    transform: translate3d(0, 0, 0) scale(1);
    transition: transform $transition-slow;
    border-radius: $border-radius-s;

    @include mq($until: sm) {
      border-radius: $border-radius-2xs;
    }
  }

  &__background,
  &__foreground {
    @include position(absolute, 0 0 0 0);

    width: 100%;
    height: 100%;
    transition: opacity $transition-slow;
  }

  &__background {
    z-index: 1;
    opacity: 0;
  }

  &__foreground {
    z-index: 2;
    opacity: 1;
  }

  &--hovered {
    #{$block-name} {
      &__inner {
        transform: translate3d(0, 0, 0) scale(0.97);
      }

      &__background {
        opacity: 1;
      }

      &__foreground {
        opacity: 0;
      }
    }
  }

  &--isTouch {
    #{$block-name} {
      &__inner {
        transform: translate3d(0, 0, 0) scale(1);
      }

      &__background {
        opacity: 1;
        z-index: 2;
      }

      &__foreground {
        opacity: 1;
        z-index: 1;
      }
    }
  }
}
